@import 'src/styles/mixins';

.actions {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    gap: 16px;
    width: 100%;

    @media #{$screen-mobile} {
        flex-direction: column;
    }

    @supports (-webkit-touch-callout: none) {
        @media not all and (max-width: 1280px) {
            flex-direction: column;
        }
    }
}

.list {
    @include scrollbar;

    height: 100%;
    margin-bottom: 16px;
    margin-top: 16px;
    overflow: auto;
}

.modal {
    display: flex;
    flex-direction: column;
    height: 100%;

    @supports (-webkit-touch-callout: none) {
        @media not all and (max-width: 1280px) {
            bottom: 0;
            left: 0;
            max-width: none;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}
